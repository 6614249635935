import Image from "next/image";
import Login from "../organisms/Login";
import React from "react";

const UserAuthTemplate = ({ googleLoginUrl, facebookLoginUrl }) => {
    const loginBtns = [
        {
            image: "/images/google-logo.svg",
            label: "Sign in with Google",
            type: "Google",
            href: googleLoginUrl,
        },
        {
            image: "/images/facebook-logo.svg",
            label: "Sign in with Facebook",
            type: "Facebook",
            href: facebookLoginUrl,
        },
    ];
    return (
        <div className="h-screen w-full fixed bg-secondary-900 flex justify-center items-center px-4">
            <div className="absolute w-full h-full z-10 hidden lg:block">
                <Image src="/images/Login.svg" layout="fill" />
            </div>
            <div className="absolute w-full h-full z-10 lg:hidden">
                <Image src="/images/LoginPhone.svg" layout="fill" />
            </div>
            <Login logo="/images/KashKounter.png" logoAlt="KashKounterLogo" logoHeight="50" logoWidth="220" loginBtns={loginBtns} className="z-50" />
        </div>
    );
};

export default UserAuthTemplate;
