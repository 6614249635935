import UserAuthTemplate from "templates/UserAuthTemplate";
import { USER_GOOGLE_LOGIN_URL, USER_FACEBOOK_LOGIN_URL } from "services/url.service";
import { isUnauthorizedRequest } from "@/services/identity.service";
import Head from "next/head";

export default function Login() {
    return (
        <div className="">
            <Head>
                <title>Login</title>
            </Head>
            <UserAuthTemplate facebookLoginUrl={USER_FACEBOOK_LOGIN_URL()} googleLoginUrl={USER_GOOGLE_LOGIN_URL()} />
        </div>
    );
}
export const getServerSideProps = async ({ req }) => {
    if (!isUnauthorizedRequest(req.cookies.AUTH)) {
        return { redirect: { destination: "/categories", permanent: false } };
    }
    return {
        props: {},
    };
};